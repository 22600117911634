<template>
	<v-dialog
		transition="dialog-top-transition"
		max-width="500"
		v-model="dialog"
	>
		<template v-slot:activator="{ on }">
			<slot name="activator_btn" v-on="on"/>
		</template>
		<template>
			<v-form
				ref="form"
				lazy-validation
			>
				<v-card>
					<v-card-title class="text-h5 text-md-h4 font-weight-400 mb-2 d-flex align-center">
						<div class="" v-if="id">Edit address</div>
						<div class="" v-else>Add new address</div>
						<v-spacer/>
						<div class="" @click="close()">
							<v-btn
								icon
								text
							>
								<v-icon size="18">mdi-close</v-icon>
							</v-btn>
						</div>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" class="d-flex align-start">
								<v-text-field
									v-model="item.name"
									label="Company name"
									outlined
									dense
									hide-details="auto"
									:rules="required"
								/>
							</v-col>

							<v-col cols="12" class="d-flex align-start">
								<v-text-field
									v-model="item.street"
									label="Street and building number"
									outlined
									dense
									hide-details="auto"
									:rules="required"
								/>
							</v-col>

							<v-col cols="12" sm="6" class="d-flex align-start">
								<v-text-field
									v-model="item.postal_code"
									label="Postal Code"
									outlined
									dense
									hide-details="auto"
									:rules="required"
								/>
							</v-col>
							<v-col cols="12" sm="6" class="d-flex align-start">
								<v-text-field
									v-model="item.city"
									label="City"
									outlined
									dense
									hide-details="auto"
									:rules="required"
								/>
							</v-col>

							<v-col cols="12" class="d-flex align-start">
								<v-select
									v-model="item.country"
									:items="dictionary.countries"
									hide-details="auto"
									item-text="name"
									label="Country"
									dense
									return-object
									outlined
									persistent-hint
									single-line
									:rules="required"
								/>
							</v-col>

						</v-row>
					</v-card-text>
					<v-card-actions class="justify-end pb-4 mt-2 px-4">
						<v-btn
							v-if="id && !item.primary"
							:loading="loadingSetAsDefault"
							color="purple"
							class="px-8"
							outlined
							@click="setAsDefault()"
							:disabled="loadingSave || loadingDelete"
						>
							Set as default
						</v-btn>
						<v-spacer/>
						<v-btn
							v-if="!id"
							:loading="loadingCreate"
							color="success"
							class="px-8"
							@click="create()"
						>
							Add
						</v-btn>

						<v-btn
							v-if="id && !item.primary"
							:loading="loadingDelete"
							color="red"
							outlined
							class="px-8 red--text"
							:disabled="loadingSetAsDefault || loadingSave"
							@click="deleteItem()"
						>
							Delete
						</v-btn>
						<v-btn
							v-if="id"
							:loading="loadingSave"
							color="blue"
							class="px-8 white--text"
							:disabled="loadingSetAsDefault || loadingDelete"
							@click="update()"
						>
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</template>
	</v-dialog>

</template>

<script>
import {validationRules} from '@/helpers/validationRules'

export default {
	name: 'DialogAddress',
	components: {},
	computed: {
		dictionary() {
			return this.$store.getters['PROFILE_CLIENT_STORE/DICTIONARIES'];
		},
	},
	data() {
		return {
			...validationRules(),

			dialog: null,

			id: null,
			item: {},

			loadingCreate: false,
			loadingSave: false,
			loadingSetAsDefault: false,
			loadingDelete: false,
		}
	},
	methods: {
		showDialog(id) {
			this.id = id;
			if (this.id) {
				this.item = this._.cloneDeep(this.$store.getters['PROFILE_CLIENT_STORE/CLIENT'].addresses)
					.find(item => item.id === this.id);
			} else {
				this.clear();
			}

			this.dialog = true;
			this.$nextTick(() => this.$refs.form.resetValidation())
		},
		create() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.loadingCreate = true;
			this.$store.dispatch('PROFILE_ADDRESS_STORE/store', this._.cloneDeep(this.item))
				.then((resp) => {
					this.loadingCreate = false;
					if (resp.data.success) {
						this.clear();
						this.dialog = false;
					}
				})
				.catch(err => {
					console.error(err)
					this.loadingCreate = false;
				});
		},
		update() {
			if (!this.$refs.form.validate()) {
				return;
			}
			this.loadingSave = true;

			this.$store.dispatch('PROFILE_ADDRESS_STORE/update', {
				id: this._.cloneDeep(this.item.id),
				payload: this._.cloneDeep(this.item),
			})
				.then((resp) => {
					this.loadingSave = false
					if (resp.data.success) {
						this.clear();
						this.dialog = false;
					}
				})
				.catch(err => {
					console.error(err)
					this.loadingSave = false
				});
		},
		setAsDefault() {
			this.loadingSetAsDefault = true;
			this.$store.dispatch('PROFILE_ADDRESS_STORE/setAsDefault', this._.cloneDeep(this.item.id))
				.then((resp) => {
					this.loadingSetAsDefault = false
					if (resp.data.success) {
						this.clear();
						this.dialog = false;
					}
				})
				.catch(err => {
					console.error(err)
					this.loadingSetAsDefault = false
				});
		},
		deleteItem() {
			this.$swal({
				icon: 'warning',
				title: 'Are you sure?',
				text: 'You will not be able to revert this.',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'Cancel',
			}).then((result) => {
				if (result.value) {
					this.loadingDelete = true;
					this.$store.dispatch('PROFILE_ADDRESS_STORE/destroy', this._.cloneDeep(this.item.id))
						.then((resp) => {
							this.loadingDelete = false
							if (resp.data.success) {
								this.clear();
								this.dialog = false;
							}
						})
						.catch(err => {
							console.error(err)
							this.loadingDelete = false
						});
				}
			});
		},
		clear() {
			this.item = {
				primary: false,
				name: null,
				street: null,
				postal_code: null,
				city: null,
				country: null,
			};
		},
		close() {
			this.$refs.form.resetValidation()
			this.dialog = false;
		}
	},
	created() {
	},
};
</script>