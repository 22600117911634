<template>
	<v-card
		outlined
	>
		<v-card-title class="text-h5 text-md-h4">
			Regulations
		</v-card-title>
		<v-card-text class="v-card__text--account-information" v-if="!loading">
			<v-form
				ref="form"
				lazy-validation
			>
				<v-list dense>
					<v-list-item
						v-for="(item, index) in approvals"
						:key="index"
					>
						<v-list-item-action>
							<v-checkbox
								class=""
								label=""
								:value="false"
								:false-value="false"
								:true-value="true"
								v-model="checkbox[item.slug]"
								hide-details="auto"
								:rules="[v => (!!v || !item.required ) || 'Please accept the required regulations!']"
							/>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title
								class="text-body-1 font-weight-400 black--text text-wrap"
							>
								<span v-if="item.required" class="red--text text-h6">* </span>
								<span v-html="item.name"/>
							</v-list-item-title>
							<small class="red--text mt-1" v-if="item.required && !checkbox[item.slug]">
								Please accept the required regulations!
							</small>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-form>
			<v-btn color="primary" large @click="save()" :loading="loadingBtn">SAVE</v-btn>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	name: 'BlockRegulations',
	components: {},
	computed: {
		client() {
			return this.$store.getters['PROFILE_CLIENT_STORE/CLIENT'];
		},
	},
	data: () => ({
		approvals: [],
		checkbox: {},
		loading: true,
		loadingBtn: false,
	}),
	methods: {
		save() {
			if (!this.$refs.form.validate()) {
				//return;
			}
			this.loadingBtn = true;

			this.$store.dispatch('PROFILE_CLIENT_STORE/updateApprovals', this.checkbox)
				.then((resp) => {
					if (resp.data.success && resp.data.approvals) {
						setTimeout(() => {
							this.$router.push({name: 'Dashboard'})
								.catch((e) => {
									console.log(e)
								});
						}, 50)
					}
					this.loadingBtn = false;
				})
				.catch(err => {
					console.error(err)
					this.loadingBtn = false;
				});
		},

	},
	created() {
		for (let i = 0; i < this.client.approvals.length; i++) {
			this.checkbox[this.client.approvals[i].slug] = true;
		}

		this.$store.dispatch('SETTINGS_APPROVALS/index')
			.then(resp => {
				let approvals = resp.data.data;

				for (let i = 0; i < approvals.length; i++) {
					let name = approvals[i].name;
					let linkAndUrl = name.split('[[')[1];
					if (linkAndUrl) {
						let route = linkAndUrl.split(']]')[0];
						if (route) {
							let [name, routerName] = route.split('|');
							let href = '#';
							if (routerName) {
								let routeObject = this.$router.getRoutes().find(i => i.name === routerName);
								if (routeObject) {
									href = routeObject.path;
								}
							}
							let a = '<a class="blue--text" href="' + href + '" target="_blank">' + name + '</a>';
							approvals[i].name = approvals[i].name.replace('[[' + route + ']]', a)
						}
					}
				}

				this.approvals = approvals;

				this.loading = false
			})
			.catch(err => {
				console.error(err)
				this.loading = false
			});
	},
};
</script>
