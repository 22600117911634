<template>
	<v-card outlined :key="key">
		<v-col cols="12" class="d-flex pb-0 " v-if="moveTo==='address'">
			<v-spacer/>
			<v-btn color="green" class="white--text" :to="{name: 'BasketIndex'}" id="profile-back_to_basket">
				Back to Basket
			</v-btn>
		</v-col>

		<v-card-title class="text-h5 text-md-h4">
			Delivery address
		</v-card-title>
		<v-card-text class="v-card__text--account-information">
			<v-row>
				<v-col cols="12" md="4">
					<v-row>
						<v-col cols="12">
							<p class="font-weight-500 mb-2">Default address</p>
							<span class="black--text">{{ primary.name }}</span><br>
							<span class="black--text">{{ primary.street }}</span><br>
							<span class="black--text">{{ primary.postal_code }}</span> <span
							class="black--text">{{ primary.city }}</span><br>
							<span class="black--text">{{ primary.country.name }}</span>
							<span v-if="primary.state" class="black--text">,
								{{ primary.state.name }}
							</span>
							<br>
							<div class="mt-2">
								<dialog-address ref="dialogAddress">
									<template v-slot:activator_btn>
										<v-btn
											color="blue"
											small
											class="white--text"
											@click="$refs.dialogAddress.showDialog(primary.id)"
										>
											Edit
										</v-btn>
									</template>
								</dialog-address>
							</div>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="8">
					<v-row>
						<v-col cols="12" sm="12" class="pb-0">
							<p class="font-weight-500">All Addresses</p>
						</v-col>

						<v-col cols="12" sm="6" v-for="(address, index) in allWithoutPrimary" :key="index">
							<span class="black--text">{{ address.name }}</span><br>
							<span class="black--text">{{ address.street }}</span><br>
							<span class="black--text">{{ address.postal_code }}</span> <span
							class="black--text">{{ address.city }}</span><br>
							<span class="black--text">{{ address.country.name }}</span>
							<span v-if="address.state" class="black--text">
											,{{ address.state.name }}
										</span><br>
							<div class="mt-2">
								<v-btn
									class="mr-1 mb-1"
									color="purple"
									small
									dark
									outlined
									:loading="loadingSetAsDefault"
									:disabled="loadingDelete"
									@click="setAsDefault(address.id)"
								>
									Set as default
								</v-btn>
								<v-btn
									class="mr-1 mb-1"
									color="red"
									small
									dark
									outlined
									:loading="loadingDelete"
									:disabled="loadingSetAsDefault"
									@click="deleteItem(address.id)"
								>
									Delete
								</v-btn>
								<dialog-address ref="dialogAddress">
									<template v-slot:activator_btn>
										<v-btn
											color="blue"
											small
											class="white--text text-uppercase mr-1 mb-1"
											@click="$refs.dialogAddress.showDialog(address.id)"
										>
											Edit
										</v-btn>
									</template>
								</dialog-address>
							</div>
						</v-col>
						<v-col cols="12">
							<dialog-address ref="dialogAddress">
								<template v-slot:activator_btn>
									<v-btn
										color="green"
										dark
										class="white--text text-uppercase"
										@click="$refs.dialogAddress.showDialog()"
									>
										Add new address
									</v-btn>
								</template>
							</dialog-address>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>
<script>
import DialogAddress from "@/views/Profile/Dialog/DialogAddress";

export default {
	name: 'BlockDeliveryAddresses',
	components: {DialogAddress},
	computed: {
		moveTo() {
			return this.$store.getters['PROFILE_CLIENT_STORE/MOVE_TO'];
		},

		addresses() {
			return this.$store.getters['PROFILE_CLIENT_STORE/CLIENT'].addresses;
		},

		primary() {
			return this.addresses.find(i => i.primary) ? this.addresses.find(i => i.primary) : this.addresses[0];
		},

		allWithoutPrimary() {
			return this.addresses.filter(i => i.id !== this.primary.id);
		},
	},
	data: () => ({
		dialog: false,
		key: 0,

		loadingSetAsDefault: false,
		loadingDelete: false,
	}),
	methods: {
		setAsDefault(id) {
			this.loadingSetAsDefault = true;
			this.$store.dispatch('PROFILE_ADDRESS_STORE/setAsDefault', id)
				.then(() => {
					this.loadingSetAsDefault = false;
				})
				.catch(err => {
					console.error(err)
					this.loadingSetAsDefault = false;
				});
		},

		deleteItem(id) {
			this.$swal({
				icon: 'warning',
				title: 'Are you sure?',
				text: 'You will not be able to revert this.',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'Cancel',
			}).then((result) => {
				if (result.value) {
					this.loadingDelete = true;
					this.$store.dispatch('PROFILE_ADDRESS_STORE/destroy', id)
						.then(() => {
							this.loadingDelete = false;
						})
						.catch(err => {
							console.error(err)
							this.loadingDelete = false;
						});
				}
			});
		},
	},
	watch: {
		addresses: {
			deep: true,
			handler() {
				this.key++;
			}
		},
	},
	created() {
	},
};
</script>
