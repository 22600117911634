<template>
	<v-card outlined>
		<v-card-title class="text-h5 text-md-h4">
			Change password
		</v-card-title>
		<v-card-text class="v-card__text--account-information">
			<v-row>
				<v-col cols="12">
					<v-row>
						<v-col cols="12">

							<dialog-change-password ref="dialogChangePassword">
								<template v-slot:activator_btn>
									<v-btn
										class="primary"
										@click="$refs.dialogChangePassword.showDialog()"
									>
										Change Your password
									</v-btn>
								</template>
							</dialog-change-password>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>
<script>

import DialogChangePassword from "@/views/Profile/Dialog/DialogChangePassword";

export default {
	name: 'BlockChangePassword',
	components: {DialogChangePassword},
	computed: {},
	data: () => ({}),
	methods: {},
	created() {
	},
};
</script>
