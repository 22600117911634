<template>
	<v-dialog
		transition="dialog-top-transition"
		max-width="450"
		v-model="dialog"
	>
		<template v-slot:activator="{ on }">
			<slot name="activator_btn" v-on="on"/>
		</template>
		<v-form
			ref="form"
			lazy-validation
		>
			<v-card>
				<v-card-title class="text-h5 text-md-h4 font-weight-400 mb-2 d-flex align-center">
					<div class="">Change password</div>
					<v-spacer/>
					<div class="" @click="close()">
						<v-btn
							icon
							text
						>
							<v-icon size="18">mdi-close</v-icon>
						</v-btn>
					</div>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-text-field
								label="Current password*"
								:type="showCurrentPassword ? 'text' : 'password'"
								:append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="showCurrentPassword = !showCurrentPassword"
								v-model="currentPassword"
								outlined
								required
								autocomplete="off"
								dense
								hide-details="auto"
								:rules="required"
							/>
						</v-col>
						<v-col cols="12">
							<v-text-field
								label="New password*"
								:type="showNewPassword ? 'text' : 'password'"
								:append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="showNewPassword = !showNewPassword"
								v-model="newPassword"
								outlined
								required
								autocomplete="off"
								dense
								hide-details="auto"
								:rules="passwordRules"
							/>
						</v-col>
						<v-col cols="12">
							<v-text-field
								label="Confirm new password*"
								:type="showConfirmNewPassword ? 'text' : 'password'"
								:append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="showConfirmNewPassword = !showConfirmNewPassword"
								v-model="confirmNewPassword"
								outlined
								required
								autocomplete="off"
								dense
								hide-details="auto"
								:rules="confirmPasswordRules"
							/>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="justify-end pb-4 mt-2 px-4">
					<v-btn
						color="blue"
						class="px-8 white--text"
						@click="update"
						:loading="loadingSave"
					>
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import {validationRules} from '@/helpers/validationRules'

export default {
	name: 'DialogChangePassword',
	components: {},
	computed: {
		dictionary() {
			return this.$store.getters['PROFILE_CLIENT_STORE/DICTIONARIES'];
		},
	},
	data() {
		return {
			...validationRules(),

			dialog: false,
			loadingSave: false,

			showCurrentPassword: false,
			showNewPassword: false,
			showConfirmNewPassword: false,

			currentPassword: '',
			newPassword: '',
			confirmNewPassword: '',

			passwordRules: [
				v => !!v || 'Field is required!',
				v => (v && v.length >= 6) || 'Please enter a longer password. Minimum length is 6 characters.',
				v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&]{6,}/.test(v) || 'The password should includes numbers, capital letters, and lower-case letters!',
			],
			confirmPasswordRules: [
				v => !!v || 'Field is required!',
				v => v === this.newPassword || 'The password confirmation does not match.',
			],
		}
	},
	methods: {
		showDialog() {
			this.dialog = true;

			this.$nextTick(() => this.$refs.form.reset())
		},

		update() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.loadingSave = true;
			this.$store.dispatch('USER_SETTINGS/updatePassword', {
				currentPassword: this.currentPassword,
				password: this.newPassword,
			})
				.then((resp) => {
					this.loadingSave = false
					if (resp.data.success) {
						this.dialog = false;
					}
				})
				.catch(err => {
					console.error(err)
					this.loadingSave = false
				});
		},

		close() {
			this.dialog = false;
		}
	},
	created() {
	},
};
</script>