<template>
	<v-card outlined>
		<v-card-title class="text-h5 text-md-h4">
			Account information
		</v-card-title>
		<v-card-text class="v-card__text--account-information">
			<v-row>
				<v-col cols="12" md="8" class="divider-negative-margin">
					<v-row>
						<v-col cols="12" sm="6">
							<p class="font-weight-500">Company Name</p>
							<span class="black--text">{{ client.name }}</span>
						</v-col>
						<v-col cols="12" sm="6">
							<p class="font-weight-500">E-mail</p>
							<span class="black--text">{{ client.email }}</span>
						</v-col>
						<v-col cols="12" sm="6">
							<p class="font-weight-500">Business types</p>
							<div>
								<v-chip color="black" outlined small label v-for="(type, index) in client.types" :key="index" class="ma-1">
									{{ type.name_en }}
								</v-chip>
							</div>
						</v-col>
						<v-col cols="12" sm="6" v-if="client.billing_detail.vat_ue">
							<p class="font-weight-500">EU VAT number</p>
							<span class="black--text">{{ client.billing_detail.vat_ue }}</span>
						</v-col>
						<v-col cols="12" sm="6" v-if="client.billing_detail.vat_uk">">
							<p class="font-weight-500">UK VAT number</p>
							<span class="black--text">{{ client.billing_detail.vat_uk }}</span>
						</v-col>
						<v-col cols="12" sm="6" v-if="client.billing_detail.company_data">">
							<p class="font-weight-500">Company data</p>
							<span class="black--text">{{ client.billing_detail.company_data }}</span>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="6">
							<p class="font-weight-500">Address</p>
							<span class="black--text">{{ client.billing_detail.street }}</span><br>
							<span class="black--text">{{ client.billing_detail.postal_code }}</span> <span
							class="black--text">{{ client.billing_detail.city }}</span><br>
							<span class="black--text">{{ client.billing_detail.country.name }}</span>
							<span v-if="client.billing_detail.state" class="black--text">,
								{{ primary.state.name }}
							</span>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="4" class="divider-negative-margin">
					<v-row>
						<v-col cols="12" sm="6">
							<p class="font-weight-500">Account created</p>
							<span class="black--text">
								{{ $dayjs.unix(client.created_at).format('YYYY-MM-DD HH:mm:ss') }}
							</span>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

		</v-card-text>
	</v-card>
</template>
<script>
export default {
	name: 'BlockAccountInformation',
	components: {},
	computed: {
		client() {
			return this.$store.getters['PROFILE_CLIENT_STORE/CLIENT'];
		},
	},
	data: () => ({}),
	methods: {},
	created() {
	},
};
</script>
