<template>
	<v-container v-if="!loading" ref="strollTo">
		<v-row dense>
			<v-col cols="12">
				<block-account-information/>
			</v-col>
			<v-col cols="12" class="">
				<block-delivery-addresses/>
			</v-col>

			<v-col cols="12" class="block-contact">
				<block-contact-details/>
			</v-col>
			<v-col cols="12">
				<block-change-password/>
			</v-col>
			<v-col cols="12" class="block-terms">
				<block-regulations/>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>

import BlockAccountInformation from "@/views/Profile/Block/BlockAccountInformation";
import BlockDeliveryAddresses from "@/views/Profile/Block/BlockDeliveryAddresses";
import BlockChangePassword from "@/views/Profile/Block/BlockChangePassword";
import BlockRegulations from "@/views/Profile/Block/BlockRegulations";
import BlockContactDetails from "@/views/Profile/Block/BlockContactDetails";

export default {
	name: 'ProfileIndex',
	components: {
		BlockContactDetails,
		BlockRegulations,
		BlockChangePassword,
		BlockDeliveryAddresses,
		BlockAccountInformation
	},
	computed: {
		moveTo() {
			return this.$store.getters['PROFILE_CLIENT_STORE/MOVE_TO'];
		},

		user() {
			return this.$store.getters['AUTH_STORE/USER'];
		},
	},
	data: () => ({
		loading: true,
	}),
	methods: {},
	created() {
		this.loading = true;
		this.$store.dispatch('PROFILE_CLIENT_STORE/getClient')
			.then(() => {
				this.loading = false;
				if (!this.user.approval) {
					setTimeout(() => {
						const el = this.$refs.strollTo;
						if (el) {
							const el = this.$el.getElementsByClassName('block-terms')[0];
							if (el) {
								el.scrollIntoView({behavior: 'smooth'});

							}
						}
					}, 350);

					this.$swal({
						icon: 'info',
						title: 'To continue, please accept the required regulations!',
					})

				} else if (this.moveTo) {
					setTimeout(() => {
						const el = this.$refs.strollTo;
						if (el) {
							const el = this.$el.getElementsByClassName('block-' + this.moveTo)[0];
							if (el) {
								el.scrollIntoView({behavior: 'smooth'});
							}
						}
					}, 350);
				}
			})
			.catch(err => {
				console.error(err)
				this.loading = false
			});

	},
	destroyed() {
		this.$store.commit('PROFILE_CLIENT_STORE/MOVE_TO_SET', null);
	}
};
</script>
