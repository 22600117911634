<template>
	<v-card outlined :key="key">
		<v-col cols="12" class="d-flex pb-0 " v-if="moveTo === 'contact'">
			<v-spacer/>
			<v-btn color="green" class="white--text" :to="{name: 'BasketIndex'}" id="profile-back_to_basket">
				Back to Basket
			</v-btn>
		</v-col>

		<v-card-title class="text-h5 text-md-h4">
			Contact details
		</v-card-title>
		<v-card-text class="v-card__text--account-information">
			<v-row>
				<v-col cols="12" md="4" v-if="primary">
					<p class="font-weight-500 mb-2">Default contact details</p>
					<span class="black--text">{{ primary.name }}</span><br>
					<span class="black--text">{{ primary.email }}</span><br>
					<span class="black--text">{{ primary.phone }}</span><br>
					<br>
					<div class="mt-2">
						<dialog-contact-details ref="dialog">
							<template v-slot:activator_btn>
								<v-btn
									color="blue"
									small
									class="white--text"
									@click="$refs.dialog.showDialog(primary.id)"
								>
									Edit
								</v-btn>
							</template>
						</dialog-contact-details>
					</div>
				</v-col>
				<v-col cols="12" md="8">
					<v-row>
						<v-col cols="12" sm="12" class="pb-0">
							<p class="font-weight-500">Yours contact details</p>
						</v-col>

						<v-col cols="12" sm="6" v-for="(address, index) in allWithoutPrimary" :key="index">
							<span class="black--text">{{ address.name }}</span><br>
							<span class="black--text">{{ address.email }}</span><br>
							<span class="black--text">{{ address.phone }}</span>
							<div class="mt-2">
								<v-btn
									class="mr-1 mb-1"
									color="purple"
									small
									dark
									outlined
									:loading="loadingSetAsDefault"
									:disabled="loadingDelete"
									@click="setAsDefault(address.id)"
								>
									Set as default
								</v-btn>
								<v-btn
									class="mr-1 mb-1"
									color="red"
									small
									dark
									outlined
									:loading="loadingDelete"
									:disabled="loadingSetAsDefault"
									@click="deleteItem(address.id)"
								>
									Delete
								</v-btn>
								<dialog-contact-details ref="dialog">
									<template v-slot:activator_btn>
										<v-btn
											color="blue"
											small
											class="white--text text-uppercase mr-1 mb-1"
											@click="$refs.dialog.showDialog(address.id)"
										>
											Edit
										</v-btn>
									</template>
								</dialog-contact-details>
							</div>
						</v-col>
						<v-col cols="12">
							<dialog-contact-details ref="dialog">
								<template v-slot:activator_btn>
									<v-btn
										color="green"
										dark
										class="white--text text-uppercase"
										@click="$refs.dialog.showDialog()"
									>
										Add new contact detail
									</v-btn>
								</template>
							</dialog-contact-details>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>
<script>
import DialogContactDetails from "@/views/Profile/Dialog/DialogContactDetails";

export default {
	name: 'BlockContactDetails',
	components: {DialogContactDetails},
	computed: {
		moveTo() {
			return this.$store.getters['PROFILE_CLIENT_STORE/MOVE_TO'];
		},

		contactDetails() {
			return this.$store.getters['PROFILE_CLIENT_STORE/CLIENT'].contacts;
		},

		primary() {
			if (this.contactDetails.length) {
				return this.contactDetails.find(i => i.primary) ? this.contactDetails.find(i => i.primary) : this.contactDetails[0];
			} else {
				return null;
			}
		},

		allWithoutPrimary() {
			return this.contactDetails.filter(i => i.id !== this.primary.id);
		},
	},
	data: () => ({
		dialog: false,
		key: 0,

		loadingSetAsDefault: false,
		loadingDelete: false,
	}),
	methods: {
		setAsDefault(id) {
			this.loadingSetAsDefault = true;
			this.$store.dispatch('PROFILE_CONTACT_DETAIL_STORE/setAsDefault', id)
				.then(() => {
					this.loadingSetAsDefault = false;
				})
				.catch(err => {
					console.error(err)
					this.loadingSetAsDefault = false;
				});
		},

		deleteItem(id) {
			this.$swal({
				icon: 'warning',
				title: 'Are you sure?',
				text: 'You will not be able to revert this.',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'Cancel',
			}).then((result) => {
				if (result.value) {
					this.loadingDelete = true;
					this.$store.dispatch('PROFILE_CONTACT_DETAIL_STORE/destroy', id)
						.then(() => {
							this.loadingDelete = false;
						})
						.catch(err => {
							console.error(err)
							this.loadingDelete = false;
						});
				}
			});
		},
	},
	watch: {
		contactDetails: {
			deep: true,
			handler() {
				this.key++;
			}
		},
	},
	created() {
	},
};
</script>
